body {
  margin: 0;
  /* font-family: 'Gelion', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ReactModal__Overlay {
  z-index: 9999;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Gelion';
  src: url('./assets/fonts/GELION-REGULAR.TTF') format('truetype');
}

@font-face {
  font-family: 'Gelion-black';
  src: 
      url('./assets/fonts/GELION-BLACK.TTF') format('truetype');
}

@font-face {
  font-family: 'Gelion-blackitalic';
  src: url('./assets/fonts/GELION-BLACKITALIC.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-bold';
  src: url('./assets/fonts/GELION-BOLD.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-bolditalic';
  src: url('./assets/fonts/GELION-BOLDITALIC.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-light';
  src: url('./assets/fonts/GELION-LIGHT.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-lightitalic';
  src: url('./assets/fonts/GELION-LIGHTITALIC.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-medium';
  src: url('./assets/fonts/GELION-MEDIUM.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-mediumitalic';
  src: url('./assets/fonts/GELION-MEDIUMITALIC.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-regularitalic';
  src: url('./assets/fonts/GELION-REGULARITALIC.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-semibold';
  src: url('./assets/fonts/GELION-SEMIBOLD.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-semibolditalic';
  src: url('./assets/fonts/GELION-SEMIBOLDITALIC.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-thin';
  src: url('./assets/fonts/GELION-THIN.TTF') format('truetype');
}
@font-face {
  font-family: 'Gelion-thinitalic';
  src: url('./assets/fonts/GELION-THINITALIC.TTF') format('truetype');
}